import React, { useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image/withIEPolyfill"
import MigrationPageContent from "../components/MigrationPageContent"
import CrButton from "../components/arrowButton"
import "../styles/signup_promo.scss"

const MigrationPage = () => {
  const footerSection = useRef(null)
  const goToFooter = () =>
    window.scrollTo({
      top: footerSection.current.offsetTop,
      behavior: "smooth",
    })
  const SignUpPageImages = useStaticQuery(graphql`
    query ImagesForMigrationPage {
      banner_image: file(relativePath: { eq: "migrationPage_banner.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client1: file(relativePath: { eq: "client-logo/abdul-latif-white.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client2: file(relativePath: { eq: "client-logo/allbirds-white.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client3: file(
        relativePath: { eq: "client-logo/rebecca-minkoff-white.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client4: file(
        relativePath: { eq: "client-logo/tony-robbins-white.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      our_work: file(relativePath: { eq: "our-work.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      our_team_1: file(relativePath: { eq: "our-team-1.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout isSignup={true} isMigration={true}>
      <SEO title="Services | Shopify Plus Agency" />
      <div className="migration-page-wrapper">
        <div className="hero-banner container-fluid g-0">
          <div className="row migration-banner-columns banner-columns justify-content-md-between g-0">
            <div className="col-lg-6  banner-text-column align-self-md-center extra-gutter left">
              <h1
                className="text text-fw-regular text-title-font gap-2x  banner-title"
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                Accelerate your migration to <strong>Shopify Plus.</strong>
              </h1>
              <p
                className="banner-text text text-black1 gap-1x extra-gutter right"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                Scale your storefront on Shopify Plus with our agile
                <br className="word-break" />
                team of Shopify Experts.
              </p>
              <div
                className=" text text-p-lg text-fw-light text-black1 "
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <CrButton
                  path="/casestudies"
                  type="light"
                  label="Get in touch"
                  btnType="button"
                  onClick={goToFooter}
                />
              </div>
            </div>
            <div className="col-lg-6 col-12 banner-image-column">
              <svg
                className="yellow-circle"
                viewBox="0 0 612 692"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="413" cy="413" r="413" fill="#FFD986" />
              </svg>
              <svg
                className="blue-circle"
                width="285"
                height="198"
                viewBox="0 0 285 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M285 250.5C285 217.604 278.521 185.03 265.932 154.638C253.343 124.246 234.891 96.6308 211.63 73.3698C188.369 50.1087 160.754 31.657 130.362 19.0682C99.9701 6.47937 67.3961 -1.43794e-06 34.5 0C1.60387 1.43794e-06 -30.9702 6.47938 -61.3622 19.0682C-91.7543 31.657 -119.369 50.1087 -142.63 73.3698C-165.891 96.6308 -184.343 124.246 -196.932 154.638C-209.521 185.03 -216 217.604 -216 250.5L34.5 250.5H285Z"
                  fill="#CFDEC8"
                />
              </svg>

              <div className="banner-images">
                <Img
                  fluid={SignUpPageImages.banner_image.childImageSharp.fluid}
                  alt="Banner"
                />
              </div>
            </div>
          </div>
        </div>
        <MigrationPageContent></MigrationPageContent>
        <div ref={footerSection}></div>
      </div>
    </Layout>
  )
}

export default MigrationPage
